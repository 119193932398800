.galleryOverlayed {
  position: fixed;
  z-index: 9999;
  width: calc(100%);
  height: calc(100%);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($primary-blue, 1);
  backdrop-filter: blur(30px);
  color: #ffffff !important;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  overflow-y: hidden;
  overflow-x: hidden;

  > .galleryOverlayed--header {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-color: rgba($primary-blue, 1);
    height: 80px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    h4 {
      margin-top: 20px;
    }

    span:last-of-type {
      font-size: 30px;
      padding: 20px;
      cursor: pointer;
    }
  }

  > .gallery__container {
    height: calc(100vh - 80px);
    overflow-y: auto;

    // .image-gallery-slide {
    //   height: calc(100vh - 160px);
    //   min-height: calc(100vh - 160px);
    //   max-height: calc(100vh - 160px);
    //   display: flex !important;
    //   align-items: center;

    //   img {
    //     border-radius: 10px;
    //   }
    // }

    // .ReactGridGallery {
    //   img {
    //     max-width: 100% !important;
    //     object-fit: cover;
    //   }
    // }
  }

  &.galleryOverlayed--active {
    opacity: 1;
    pointer-events: all;
  }
}
