.collapsible {
  background-color: rgba($primary-blue, 0.2);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: background-color 0.1s;
}

.collapsible-active,
.collapsible:hover {
  background-color: rgba($primary-blue, 0.4);
}

.collapsible-content {
  padding: 0 18px;
  background-color: rgba($primary-blue, 0.2);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.collapsible:after {
  content: "\02795";
  font-size: 13px;
  float: right;
  margin-left: 5px;
}

.collapsible-active:after {
  content: "\2796";
}
