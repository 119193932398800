@media screen and (max-width: 670px) {
  .start-section {
    flex-direction: column-reverse;
    height: auto;
    margin-top: 80px;

    .text-container {
      width: 100%;
      max-width: 100%;
      padding: 40px 20px;
    }

    .image-container {
      width: 100%;
      max-width: 100%;
      height: calc(200px);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
}
