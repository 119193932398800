.imprintOverlay {
  position: fixed;
  z-index: 9999;
  max-width: 1000px;
  right: 10px;
  left: 10px;
  top: 10px;
  bottom: 10px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 400px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(30px);
  border-radius: 4px;
  transition: opacity 0.3s ease-in-out;
  overflow-y: hidden;
  overflow-x: hidden;

  > .imprintOverlay--header {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 80px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    h4 {
      margin-top: 20px;
    }

    span:last-of-type {
      font-size: 30px;
      padding: 20px;
      cursor: pointer;
    }
  }

  > .imprintOverlay--body {
    padding: 0 20px;
    height: calc(100vh - 80px);
    overflow-y: auto;
  }
}
