$content_width: 1200px;
$content_padding: 50px;

$black: #222222;
$white: #ffffff;

$light_blue: #d4edfc;
$primary_blue: #2b204b;
$turquoise_blue: #10afcb;

$yellow: #ebc500;
