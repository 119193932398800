@media screen and (max-width: 768px) {
  section.training-section {
    flex-direction: column;
    > div {
      .yellow--dotted--border {
        &:after {
          right: -13px !important;
          left: -13px !important;
        }
      }
      > div {
        max-width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-right: 20px !important;
        padding-left: 20px;
      }
      &:first-of-type {
        > div {
          margin-left: 0;
        }
      }
    }
  }
}
