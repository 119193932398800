@media screen and (max-width: 670px) {
  header {
    height: 80px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    nav {
      ul {
        margin: 20px 20px 0 0;
      }
    }

    .logo {
      span {
        display: none;
      }
      img {
        height: 50px;
        margin-left: 20px;
      }
    }
  }
}
