@import url("https://fonts.googleapis.com/css2?family=Allison&family=Cabin:ital,wght@0,400..700;1,400..700&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Cabin", sans-serif;
  // background-color: $primary_blue;
  font-size: 17px;
  line-height: 1.5;
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  overflow-x: hidden;

  p {
    text-align: justify;
  }
  textarea,
  input,
  button {
    font-family: "Cabin", sans-serif;
    font-size: 17px;
    line-height: 1.5;
  }
}

h1 {
  margin: 5vw 0 0 0;
  margin-top: 5vw;
  font-size: 6vw;
  line-height: 5vw;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  color: $primary_blue;
}

h2 {
  font-size: 30px;
  line-height: 1.2;
  font-weight: 100;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 10px;
}

h4 {
  margin: 0;
  font-size: 25px;
  line-height: 1.2;
  font-weight: 100;
  margin-bottom: 0px;
}

h5 {
  margin: 0;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 100;
  margin-bottom: 0px;
}

.handwritten {
  font-family: "Allison", cursive;
  color: $yellow;
  margin: 0;
  text-shadow: 0 0 1px $yellow;
  white-space: nowrap;
  font-size: 4rem;
  letter-spacing: -3px;

  &.handwritten--start {
    margin-top: -4vw;
    transform: rotate(-3deg);
    font-size: 8vw;
    letter-spacing: -3px;
  }
}

.card {
  border-radius: 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.flex--5 {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;

  > div:not(.galleryOverlayed) {
    flex: 1;
    width: 20%;
    max-width: 20%;
  }
}

.flex-1-1 {
  display: flex;
  gap: 30px;

  > div {
    flex: 1;
  }
}

.flex-1-2 {
  display: flex;
  gap: 30px;

  > div:nth-of-type(1) {
    flex: 1;
  }

  > div:nth-of-type(2) {
    flex: 2;
  }
}

.flex-2-1 {
  display: flex;
  gap: 30px;

  > div:nth-of-type(1) {
    flex: 2;
  }

  > div:nth-of-type(2) {
    flex: 1;
  }
}

.bg--yellow {
  background-color: $yellow;
}

.bg--white {
  background-color: $white;
}

.bg--blue {
  background-color: $primary_blue;
  color: #ffffff;
}

.graphic_wave {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100px;
    background-image: url("../../public/images/graphics/Wave.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    top: -20px;
    left: -110px;
    width: 110px;
    height: 80px;
  }
}

@media screen and (max-width: 1080px) {
  .flex--5 {
    display: flex;
    gap: 30px;

    > div:not(.galleryOverlayed) {
      flex: 1;
      width: calc(25% - (23px));
      min-width: calc(25% - (23px));
      max-width: calc(25% - (23px));
    }
  }
}

@media screen and (max-width: 920px) {
  .flex--5 {
    display: flex;
    gap: 30px;

    > div:not(.galleryOverlayed) {
      flex: 1;
      width: calc(33.3333% - (20px));
      min-width: calc(33.3333% - (20px));
      max-width: calc(33.3333% - (20px));
    }
  }
}
@media screen and (max-width: 768px) {
  .flex--5 {
    display: flex;
    gap: 30px;

    > div:not(.galleryOverlayed) {
      flex: 1;
      width: calc(50% - (15px));
      min-width: calc(50% - (15px));
      max-width: calc(50% - (15px));
    }
  }
}
@media screen and (max-width: 670px) {
  h1 {
    margin-top: 0;
    font-size: 2.8rem;
    line-height: 2.6rem;
  }
  .handwritten {
    &.handwritten--start {
      margin-top: -30px;
      transform: none;
      font-size: 3.5rem;
      letter-spacing: -1px;
    }
  }
}
