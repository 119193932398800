footer {
  > div {
    max-width: calc($content_width);
    margin: 0 auto;
    display: flex;

    > div {
      flex: 1;
      width: 50%;
      max-width: 50%;
      padding-left: $content_padding;
      padding-right: $content_padding;
      padding-top: 40px;
      padding-bottom: 40px;

      &:first-of-type {
        border-right: 5px dotted $yellow;
      }

      > div {
        display: flex;

        a {
          color: #ffffff;
        }

        img {
          margin-right: 10px;
          margin-top: 10px;
          height: 80px;
        }
      }
    }
  }
}
