@media screen and (max-width: 768px) {
  footer {
    p {
      text-align: left;
    }
    > div {
      flex-direction: column;
      > div {
        width: 100%;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 0px !important;
        &:first-of-type {
          margin-bottom: 20px;
          padding-bottom: 0px;
          border-right: none;
        }
        .handwritten {
          font-size: 3rem;
        }
        svg {
          display: none;
        }
      }
    }
  }
}
