@media screen and (max-width: 900px) {
  .photosevents-section {
    > div {
      flex-wrap: wrap;
      a {
        width: calc(25% - 23px);
        max-width: calc(25% - 23px);
        min-width: calc(25% - 23px);
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .photosevents-section {
    > div {
      flex-wrap: wrap;
      a {
        width: calc(33.33% - 20px);
        max-width: calc(33.33% - 20px);
        min-width: calc(33.33% - 20px);
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .photosevents-section {
    > div {
      flex-wrap: wrap;
      a {
        width: calc(50% - 15px);
        max-width: calc(50% - 15px);
        min-width: calc(50% - 15px);
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .photosevents-section {
    > div {
      flex-wrap: wrap;
      a {
        width: calc(100% - 0px);
        max-width: calc(100% - 0px);
        min-width: calc(100% - 0px);
      }
    }
  }
}
