@media screen and (max-width: 768px) {
  .contact-section {
    > div {
      flex-direction: column-reverse;
      > div {
        padding-left: 20px;
        padding-right: 20px;
        .handwritten {
          font-size: 3rem;
        }
      }
      .contact-image {
        display: flex;
        justify-content: center;
        margin-top: -150px;
        margin-bottom: 0;
        img {
          position: relative;
          transform: rotate(90deg);
          width: 80%;
        }
      }
    }
  }
}
