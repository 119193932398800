.anpaddelnOverlayed {
  position: fixed;
  z-index: 9999;
  max-width: 1000px;
  right: 10px;
  left: 10px;
  top: 10px;
  bottom: 10px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 400px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(30px);
  border-radius: 4px;
  transition: opacity 0.3s ease-in-out;
  overflow-y: hidden;
  overflow-x: hidden;

  > .anpaddelnOverlayed--header {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 80px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    h4 {
      margin-top: 20px;
    }

    span:last-of-type {
      font-size: 30px;
      padding: 20px;
      cursor: pointer;
    }
  }

  > .form__container {
    height: calc(100vh - 80px);
    padding: 20px;
    overflow-y: auto;

    form {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 20px;

      > div {
        width: calc(50% - 10px);
        padding: 0 0 0 0;

        > h3 {
          margin: 0;
        }
        > p {
          margin: 0;
        }

        > input,
        > label {
          width: 100%;
        }
        > label {
          font-size: 14px;
        }

        .submit-button {
          position: relative;
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
        }

        .submit-button.loading {
          cursor: not-allowed;
          opacity: 0.8;
          background-color: #ffffff;
          color: $primary_blue;
        }

        button {
          background-color: $primary_blue;
          color: #ffffff;
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 2px;
          margin: 20px 0 10px 0;
          border: 2px solid $primary_blue;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #ffffff;
            color: $primary_blue;
          }

          .loader {
            width: 60px;
            aspect-ratio: 4;
            margin-left: 10px;
            --_g: no-repeat
              radial-gradient(circle closest-side, #000 90%, #0000);
            background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
            background-size: calc(100% / 3) 100%;
            animation: l7 1s infinite linear;
          }
          @keyframes l7 {
            33% {
              background-size: calc(100% / 3) 0%, calc(100% / 3) 100%,
                calc(100% / 3) 100%;
            }
            50% {
              background-size: calc(100% / 3) 100%, calc(100% / 3) 0%,
                calc(100% / 3) 100%;
            }
            66% {
              background-size: calc(100% / 3) 100%, calc(100% / 3) 100%,
                calc(100% / 3) 0%;
            }
          }
        }

        .small {
          font-size: 12px;
          margin: 0;
          line-height: 1;
          opacity: 0.6;
        }

        &.fullWidth {
          min-width: 100%;
        }
      }
    }
  }
}

.open--form--link {
  display: block;
  text-align: center;
  padding: 20px 10px;
  margin: 25px auto 20px;
  width: calc(100% - 100px);
  max-width: calc(1200px - 10vw - 100px);
  background-color: $turquoise_blue;
  color: #ffffff;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4), 1px 2px 5px rgba(0, 0, 0, 0.2);
  animation: pulse 2s infinite;

  &:hover {
    background-color: darken($color: $turquoise_blue, $amount: 5);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($turquoise_blue, 0.4),
      1px 2px 5px rgba(0, 0, 0, 0.2);
  }
  70% {
    box-shadow: 0 0 0 30px rgba($turquoise_blue, 0),
      1px 2px 5px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($turquoise_blue, 0), 1px 2px 5px rgba(0, 0, 0, 0.2);
  }
}
