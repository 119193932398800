.GalleryView {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
  justify-content: center;

  .GalleryView__item {
    position: relative;

    img {
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      cursor: pointer;
      aspect-ratio: 1 / 1;

      &:nth-of-type(1) {
        animation: changeBackgroundColor 1s infinite;
      }

      &:nth-of-type(2) {
        position: fixed;
        opacity: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        object-fit: contain;
        left: 0;
        z-index: 9999;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.8);
        transform: scale3d(0.9, 0.9, 0.9);
        transition: all 0.1s ease-in-out;

        &.resized {
          opacity: 1;
          pointer-events: all;
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}

@keyframes changeBackgroundColor {
  0% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
