@media screen and (max-width: 768px) {
  section.about-section {
    flex-direction: column;
    > div {
      > div {
        max-width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;

        .flex-1-1 {
          flex-direction: column;
          > div {
            img {
              height: auto !important;
              aspect-ratio: 16/9;
            }
          }
        }
      }
      &:first-of-type {
        > div {
          margin-left: 0;
        }
      }
    }
  }
}
