.contact-section {
  > div {
    max-width: calc($content_width);
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 0 auto;
    display: flex;
    > div {
      flex: 1;
      padding-left: $content_padding;
      padding-right: $content_padding;
      .handwritten {
        color: $primary_blue;
        font-size: 6rem;
        line-height: 1;
      }
      .success-form {
        background-color: green;
        line-height: 1;
        display: flex;
        align-items: center;
        color: white;
        padding: 15px 20px;
        margin: 30px 0 0 0;
        border-radius: 10px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        svg {
          margin-right: 10px;
          font-size: 1.5rem;
        }
      }
      form {
        margin-top: 30px;
        input,
        textarea {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          &:focus {
            border-color: $primary_blue;
          }
        }
        textarea {
          height: 150px;
        }
        button {
          background-color: $primary_blue;
          border: none;
          color: white;
          padding: 15px 25px;
          border-radius: 4px;
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .contact-image {
      position: relative;
      margin-top: -111px;
      margin-bottom: -60px;
      overflow: hidden;
      img {
        width: 100%;
        position: absolute;
        left: 0;
      }
    }
  }
}
