.imprint-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 12px;
  > div > a {
    margin: 0 10px 0 0;
    color: #ffffff;
  }
}
